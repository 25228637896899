<template>
  <div id="classification">
    <search :show="searchShow" @close="getSearchShow" />

    <van-search
      v-model="search"
      shape="round"
      background="#fff"
      placeholder="请输入名称、货号、规格、品牌搜索"
      :clearable="false"
      @focus="searchShow = true"
    >
      <template #left-icon>
        <svg-icon icon-class="home_nav_search_icon" />
      </template>
      <template #right-icon>
        <van-button round @click="$router.push(`/goodsList?like=${search}`)"
          >搜索</van-button
        >
      </template>
    </van-search>
    <header class="van-hairline--bottom">
      <div
        class="class_item"
        v-for="item in classification"
        :key="item.categoryId"
        :class="item.pitchOn ? 'pitch_on' : ''"
        @click="classSelect(item)"
      >
        <img :src="item.categoryImage" alt="" />
        <p>{{ item.categoryName }}</p>
      </div>
    </header>
    <div class="all" @click="(allClassShow = true), (moreCondition = false)">
      全部<svg-icon icon-class="all_icon" />
    </div>

    <div class="all_class">
      <transition name="van-fade">
        <div
          class="mask"
          v-show="allClassShow"
          @click="allClassShow = false"
        ></div>
      </transition>
      <div
        class="box"
        ref="show_box"
        :style="allClassShow ? `height: ${boxHeight}px` : 'height:0;'"
      >
        <div class="title">
          <p>全部分类</p>
          <p @click="allClassShow = false">
            收起<svg-icon icon-class="home_more_icon1" />
          </p>
        </div>
        <div class="all_class_content">
          <div
            v-for="item in classification"
            :key="item.categoryId"
            class="class"
            :class="item.pitchOn ? 'pitch_on' : ''"
            @click="classSelect(item)"
          >
            <img :src="item.categoryImage" alt="" width="44" height="44" />
            <p>{{ item.categoryName }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="content">
      <div class="more">
        <transition name="van-fade">
          <div
            class="mask"
            v-show="moreCondition"
            @click="moreCondition = false"
          ></div>
        </transition>
        <div
          class="condition_content"
          :style="
            pitchOnCondition === 0
              ? 'max-height: 214px;'
              : 'max-height:0;padding:0'
          "
        >
          <template v-if="brand.length">
            <p
              v-for="item in brand"
              :key="item.id"
              @click="selectBrand(item.id)"
              :style="item.id === onBrandId ? 'color:#009A4D' : ''"
            >
              {{ item.brandName }}
            </p>
          </template>
          <van-empty description="" v-else />
        </div>
        <div
          class="condition_content"
          :style="
            pitchOnCondition === 1
              ? 'max-height: 214px;'
              : 'max-height:0;padding:0'
          "
        >
          <p
            v-for="item in time"
            :key="item"
            @click="selectDay(item)"
            :style="item == onTime ? 'color:#009A4D' : ''"
          >
            {{ item }}天以内
          </p>
        </div>
        <div
          class="condition_content"
          :style="
            pitchOnCondition === 2
              ? 'max-height: 214px;'
              : 'max-height:0;padding:0'
          "
        >
          <p
            v-for="(item, index) in city"
            :key="item"
            @click="selectCity(index)"
            :style="item == onCity ? 'color:#009A4D' : ''"
          >
            {{ item }}
          </p>
        </div>
      </div>

      <div class="sidebar">
        <div
          class="item"
          v-for="item in secondLevel"
          :key="item.categoryId"
          :class="item.pitchOn ? 'item--select' : ''"
          @click="classSelect(item)"
        >
          <span>{{ item.categoryName }}</span>
        </div>
      </div>

      <div class="top van-hairline--bottom">
        <div class="condition">
          <button
            class="brand"
            :style="
              pitchOnCondition === item.i ? 'border-radius:10px 10px 0 0 ' : ''
            "
            v-for="item in condition"
            :key="item.i"
            @click="[(pitchOnCondition = item.i), (moreCondition = true)]"
          >
            {{ item.text
            }}<svg-icon
              icon-class="down_menu_icon"
              :style="
                pitchOnCondition === item.i ? 'transform: rotate(0deg);' : ''
              "
            />
            <span v-show="pitchOnCondition === item.i"></span>
          </button>
        </div>

        <div class="sort">
          <p @click="switchover(0)" :class="index === 0 ? 'pitch_on' : ''">
            综合排序
          </p>
          <p @click="switchover(1)" :class="index === 1 ? 'pitch_on' : ''">
            销量
            <span>
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 1 && order ? 'pitch_on' : ''"
              />
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 1 && !order ? 'pitch_on' : ''"
              />
            </span>
          </p>
          <p @click="switchover(2)" :class="index === 2 ? 'pitch_on' : ''">
            价格
            <span>
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 2 && order ? 'pitch_on' : ''"
              />
              <svg-icon
                icon-class="home_more_icon1"
                :class="index === 2 && !order ? 'pitch_on' : ''"
              />
            </span>
          </p>
        </div>
      </div>

      <div class="goods_box">
        <div
          class="goods"
          v-for="(list, i) in goods"
          :key="i"
          @click="goDetails(list)"
        >
          <img v-lazy="list.filePath" alt="" />
          <div class="details">
            <h3>{{ list.commodityName }}</h3>
            <div class="price_box">
              <p class="price">
                <span>￥</span
                ><b style="margin-right: 2px" v-if="list.goodsStatus === 1">{{
                  list.entity.price.toFixed(2)
                }}</b
                ><b
                  style="margin-right: 2px"
                  v-else-if="list.goodsStatus === 2"
                  >{{
                    list.querySecKillCommodityListRsp.retailSeckillPrice.toFixed(
                      2
                    )
                  }}</b
                >

                <b
                  style="margin-right: 2px"
                  v-else-if="userType === 'PURCHASE'"
                  >{{ list | tradePrice }}</b
                ><b style="margin-right: 2px" v-else>{{ list | retailPrice }}</b
                >/{{ list.prickle }}
              </p>
              <p class="address">
                {{ list.province | city }}{{ list.city | city }}
              </p>
            </div>
            <p class="store">
              <svg-icon icon-class="store_icon" style="margin-right: 4px" />{{
                list.storeName
              }}
            </p>
          </div>
        </div>

        <van-loading size="30" vertical v-show="isLoading"
          >加载中...</van-loading
        >
        <van-empty
          description="暂无商品"
          v-show="!isLoading && !goods.length"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Search from "@/components/Search";
import { preferential } from "@/utils/validate";
import { mapState } from "vuex";

export default {
  name: "classification",
  components: { Search },
  computed: {
    ...mapState("user", ["userType", "tzInfo", "isLogin", "userInfo"]),
  },
  data() {
    return {
      searchShow: false, //搜索框显示隐藏
      boxHeight: 0,
      search: "",
      activeKey: 0,
      classification: [], //类目
      secondLevel: [], //二级
      goods: [], //商品
      allClassShow: false, //所有类目显示隐藏
      moreCondition: false, //品牌、上架、产地条件显示隐藏
      pitchOnCondition: -1, //品牌、上架、产地条件
      order: true, //desc asc 升降序
      sortColumn: "", //排序字段
      index: 0,
      isLoading: true,
      condition: [
        {
          text: "品牌",
          i: 0,
        },
        {
          text: "上架时间",
          i: 1,
        },
        {
          text: "产地",
          i: 2,
        },
      ],

      brand: [],
      time: [7, 30, 90],
      city: ["北京", "上海", "广东", "福建", "湖南"],
      onBrandId: "",
      onTime: 0,
      onCity: "",
      arr: [],
    };
  },
  watch: {
    moreCondition(val, oldVal) {
      if (!val) {
        this.pitchOnCondition = -1;
      }
    },

    index(val, oldVal) {
      this.order = true;
    },
    classification: {
      handler: function (val, oldVal) {
        this.index = 0;

        val.forEach((stair) => {
          if (stair.pitchOn) {
            this.secondLevel = stair.subItemList;
          }
        });

        this.arr = [];
        this.classification.forEach((stair) => {
          if (stair.pitchOn) {
            // this.arr.push(stair.categoryId);
            if (stair.subItemList) {
              stair.subItemList.forEach((secondLevel) => {
                if (secondLevel.pitchOn) {
                  this.arr.push(secondLevel.categoryId);
                  if (secondLevel.subItemList) {
                    secondLevel.subItemList.forEach((threeLeve) => {
                      this.arr.push(threeLeve.categoryId);
                    });
                  }
                }
              });
            }
          }
        });
        this.getAllBrand();
        this.getCommList();
      },
      deep: true,
      // immediate: true,
    },
    $route: {
      handler: function (val, oldVal) {},
    },
  },

  filters: {
    city(city) {
      return city.split("省")[0].split("市")[0];
    },
    tradePrice(value) {
      return preferential(value, "tradePrice").price;
    },
    retailPrice(value) {
      return preferential(value, "retailPrice").price;
    },
  },
  created() {
    // this.getClassification();
  },
  activated() {
    this.getClassification();
  },
  deactivated() {
    this.moreCondition = false;
  },
  methods: {
    getSearchShow(val) {
      this.search = val;
      this.searchShow = false;
    },
    //获取分类
    async getClassification() {
      const data = await this.$API.platform.Classification({
        object: {
          enterpriseId: "",
        },
      });
      this.classification = data.data.data.categoriesCatalog;
      this.classification.forEach((arr) => {
        if (!arr.subItemList) {
          arr.subItemList = [
            {
              categoryName: arr.categoryName,
              categoryId: arr.categoryId,
              rank: 2,
            },
          ];
        }
      });
      const that = this;
      a(this.classification);
      function a(arr) {
        arr.forEach((element, i) => {
          if (that.$route.query.categoryId == element.categoryId) {
            that.$set(element, "pitchOn", true);
          } else {
            if (that.$route.query.categoryId) {
              if (element.rank == 2) {
                if (i === 0) {
                  that.$set(element, "pitchOn", true);
                } else {
                  that.$set(element, "pitchOn", false);
                }
              }
            } else {
              if (i === 0) {
                that.$set(element, "pitchOn", true);
              } else {
                that.$set(element, "pitchOn", false);
              }
            }
          }
          if (element.subItemList) {
            a(element.subItemList);
          }
        });
      }

      this.$nextTick(function () {
        this.boxHeight = this.$refs.show_box.scrollHeight;
      });
    },
    //品牌
    async getAllBrand() {
      const data = await this.$API.brand.AllBrand({
        object: {
          brandName: "",
          categoryId: this.arr,
          name: "",
        },

        pageNum: 0,
        pageSize: 0,
      });
      this.brand = data.data.data;
    },
    //商品
    async getCommList() {
      this.isLoading = true;
      this.goods = [];
      await this.$API.homePage
        .CommList({
          object: {
            brandId: this.onBrandId, //品牌id
            ceCategoryId: this.arr, //分类id
            order: this.order ? "desc" : "asc", // 排序 desc/asc
            queryCriteria: -this.onTime, //时间
            sortColumn: this.sortColumn, //排序字段
            productionPlace: this.onCity, //地点
            memberId: this.isLogin ? this.userInfo.id : undefined,
            userId: this.tzInfo ? this.tzInfo.id : "",
          },
          pageNum: 0,
          pageSize: 0,
        })
        .then((res) => {
          this.isLoading = false;
          this.goods = res.data.data.map((a) => {
            return {
              ...a,
              filePath: a.filePath.split(",")[0].endsWith(".mp4")
                ? a.filePath.split(",")[1]
                : a.filePath.split(",")[0],
            };
          });
          this.goods.forEach((item) => {
            if (
              item.newUser &&
              item.entity &&
              item.querySecKillCommodityListRsp
            ) {
              if (
                item.entity.price <=
                item.querySecKillCommodityListRsp.retailSeckillPrice
              ) {
                item.goodsStatus = 1;
              } else {
                item.goodsStatus = 2;
              }
            } else if (item.newUser && item.entity) {
              item.goodsStatus = 1;
            } else if (item.querySecKillCommodityListRsp) {
              item.goodsStatus = 2;
            } else {
              item.goodsStatus = 0;
            }
          });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    classSelect(item) {
      this.sortColumn = "";
      this.index = 0;
      this.onBrandId = "";
      this.onTime = 0;
      this.onCity = "";
      this.moreCondition = false;
      if (item.rank === 1 && this.$route.query.categoryId != item.categoryId) {
        this.$router.replace({
          path: "classification",
          query: {
            categoryId: item.categoryId,
          },
        });
        this.allClassShow = false;
        this.classification.forEach((element) => {
          element.pitchOn = false;
          if (element.subItemList) {
            element.subItemList.forEach((arr) => {
              arr.pitchOn = false;
            });
            element.subItemList[0].pitchOn = true;
          }
        });
      } else if (item.rank === 2) {
        this.classification.forEach((element) => {
          if (element.pitchOn && element.subItemList) {
            element.subItemList.forEach((arr) => {
              arr.pitchOn = false;
            });
          }
        });
      }
      item.pitchOn = true;
    },
    switchover(i) {
      if (this.index === i && i === 0) return;
      if (this.index != i) this.order = true;
      this.index = i;
      switch (i) {
        case 0:
          this.sortColumn = "";
          break;
        case 1:
          this.order = !this.order;
          break;
        case 2:
          this.order = !this.order;
          this.sortColumn = "retailPrice";

          break;
        default:
          break;
      }
      if (this.index === 1) return;
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        this.getCommList();
      }, 500);
    },
    goDetails(goods) {
      this.$router.push({
        path: "/goodsDetails",
        query: {
          barcode: goods.barcode,
          enterpriseId: goods.enterpriseId,
        },
      });
    },
    selectBrand(val) {
      if (this.onBrandId == val) {
        this.onBrandId = "";
      } else {
        this.onBrandId = val;
      }
      this.moreCondition = false;
      this.getCommList();
    },
    selectDay(val) {
      if (this.onTime == val) {
        this.onTime = 0;
      } else {
        this.onTime = val;
      }
      this.moreCondition = false;
      this.getCommList();
    },
    selectCity(val) {
      if (this.onCity == this.city[val]) {
        this.onCity = "";
      } else {
        this.onCity = this.city[val];
      }
      this.moreCondition = false;
      this.getCommList();
    },
  },
};
</script>

<style lang="scss" scoped>
#classification {
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: 500px;
  margin: 0 auto;
  .van-search {
    padding: 9px 15px 0;

    ::v-deep .van-search__content {
      //       width: 345px;
      // height: 30px;
      border: 1px solid #009a4d;
      .van-cell {
        padding: 0;
        overflow: inherit;
        .van-field__left-icon {
          display: flex;
          align-items: center;
        }
        .van-cell__value {
          .van-field__body {
            position: relative;

            .van-field__control {
              height: 28px;
              font-size: 12px;
              padding: 0 60px 0 4px;
            }
            .van-field__control::-webkit-input-placeholder {
              /* WebKit browsers */
              color: #999999;
            }
            .van-field__control:-moz-placeholder {
              /* Mozilla Firefox 4 to 18 */
              color: #999999;
            }
            .van-field__control::-moz-placeholder {
              /* Mozilla Firefox 19+ */
              color: #999999;
            }
            .van-field__control:-ms-input-placeholder {
              /* Internet Explorer 10+ */
              color: #999999;
            }
            .van-field__right-icon {
              .van-button {
                position: absolute;
                top: -1px;
                right: -1px;
                padding: 0 16px;
                height: 30px;
                background: linear-gradient(180deg, #0ae377 0%, #009a4d 100%);
                border: none;
                color: #ffffff;
              }
            }
          }
        }
      }
    }
  }
  header {
    height: 100px;
    background: #fff;
    display: flex;
    padding: 12px 7.5px 20px;
    margin-right: 32px;
    overflow-x: auto;
    .class_item {
      flex-shrink: 0;
      margin: 0 7.5px;
      text-align: center;
      img {
        width: 44px;
        height: 44px;
        border: 1px solid transparent;
        border-radius: 50%;
        transition: 0.2s all;
      }
      p {
        padding: 0 8px;
        line-height: 20px;
        // background: #009A4D;
        font-size: 14px;
        border-radius: 10px;
        transition: 0.2s all;
      }
    }
    .pitch_on {
      img {
        border-color: #009a4d;
      }
      p {
        background: #009a4d;
        color: #fff;
      }
    }
  }
  .all {
    position: absolute;
    top: 39px;
    right: 0;
    width: 32px;
    height: 98px;
    background: #fff;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.16);
    color: #333333;
    padding: 22px 9px;
    .svg-icon {
      margin-top: 5px;
    }
  }
  header::-webkit-scrollbar {
    display: none;
  }
  .all_class {
    overflow: hidden;
    .mask {
      position: fixed;
      top: 39px;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(51, 51, 51, 0.39);
      z-index: 200;
    }
    .box {
      position: fixed;
      top: 39px;
      left: 0;
      right: 0;
      max-width: 500px;
      margin: 0 auto;
      z-index: 201;
      background: #fff;
      transition: 0.3s height;
      overflow: hidden;
      border-radius: 0 0 15px 15px;
      .title {
        display: flex;
        justify-content: space-between;
        color: #333333;
        margin: 15px;
        margin-bottom: 0;
        .svg-icon {
          transform: rotate(-90deg);
          font-size: 10px;
          margin-left: 4px;
          margin-bottom: 2px;
        }
      }
      .all_class_content {
        margin: 15px;
        margin-top: 0;
        .class {
          display: inline-block;
          text-align: center;
          width: 20%;
          margin-top: 18px;
          img {
            border-radius: 50%;
            border: 1px solid transparent;
            // border: 1px solid #009A4D;
          }
          p {
            line-height: 20px;
            color: #666666;
          }
        }
        .pitch_on {
          img {
            border-color: #009a4d;
          }
          p {
            padding: 0 7px;
            background: #009a4d;
            color: #fff;
            border-radius: 10px;
            font-size: 13px;
          }
        }
      }
    }
  }
  .content {
    position: absolute;
    top: 139px;
    bottom: 50px;
    left: 0;
    right: 0;
    overflow: scroll;

    .more {
      position: absolute;
      top: 0;
      left: 90px;
      right: 0;
      bottom: 0;
      .mask {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(51, 51, 51, 0.39);
        z-index: 90;
      }
      .condition_content {
        position: absolute;
        top: 42px;
        right: 0;
        width: 100%;
        background: #f5f5f5;
        border-radius: 0px 0px 10px 10px;
        z-index: 101;
        overflow: hidden;
        transition: 0.3s all;
        padding-top: 16px;
        overflow-y: auto;

        p {
          float: left;
          width: 33%;
          margin-bottom: 14px;
          text-align: center;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0 10px;
        }
        .van-empty {
          padding-top: 0;
          margin-top: -20px;
        }
      }
    }
    .sidebar {
      width: 90px;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      background: #f5f5f5;
      .item {
        color: #666666;
        background: #f5f5f5;
        text-align: center;
        padding: 14px 12px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: 0.2s all;
      }
      .item--select {
        background: #fff;
        font-size: 16px;
        font-weight: bold;
        color: #009a62;
      }
      .van-sidebar-item--select::before {
        display: none;
      }
    }
    .top {
      position: absolute;
      top: 0;
      left: 90px;
      right: 0;

      .condition {
        display: flex;
        justify-content: space-evenly;
        padding: 14px 0;
        position: relative;
        background: #fff;
        z-index: 100;
        button {
          display: flex;
          align-items: center;
          height: 20px;
          background: #f5f5f5;
          border-radius: 10px;
          line-height: 20px;
          color: #666666;
          border: none;
          padding: 0 12px;
          position: relative;
          .svg-icon {
            transform: rotate(-180deg);
            font-size: 8px;
            margin-left: 4px;
            transition: 0.3s;
          }
          span {
            position: absolute;
            top: 20px;
            width: 100%;
            left: 0;
            height: 10px;
            background: #f5f5f5;
            z-index: 91;
          }
        }
      }
      .sort {
        display: flex;
        justify-content: flex-end;
        padding: 8px 16px;
        p {
          display: flex;
          margin-left: 28px;
          color: #666666;
          span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 4px;
            .svg-icon {
              font-size: 7px;
              font-weight: bold;
              color: #666666;
            }
            .svg-icon:nth-child(1) {
              transform: rotate(-90deg);
            }
            .svg-icon:nth-child(2) {
              transform: rotate(90deg);
            }
            .pitch_on {
              color: #009a4d;
            }
          }
        }
        .pitch_on {
          color: #009a4d;
        }
      }
    }

    .goods_box {
      overflow-y: scroll;
      overflow-x: hidden;
      position: absolute;
      top: 83px;
      left: 90px;
      right: 0;
      bottom: 0;
      background: #f9f9f9;
      ::v-deep .van-pull-refresh {
        min-height: 100%;
      }
      .goods {
        padding: 14px 12px;
        display: flex;
        background: #fff;
        img {
          width: 100px;
          height: 100px;
          display: block;
          margin-right: 15px;
          flex-shrink: 0;
        }
        .details {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;

          h3 {
            font-size: 16px;
            line-height: 23px;
            color: #333333;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .price_box {
            margin: 4px 0;
            line-height: 23px;
            display: flex;
            justify-content: space-between;
            .price {
              font-size: 14px;
              color: #fb4e10;
              flex-shrink: 0;
              margin-right: 8px;
              span {
                font-size: 12px;
                font-style: normal;
                display: inline-block;
                transform: scale(0.8);
                -webkit-transform: scale(0.8);
                -moz-transform: scaleY(0.8);
                position: relative;
                left: 2px;
                top: 1px;
              }
            }
            .address {
              font-size: 12px;
              color: #999999;
              min-width: 48px;
              line-height: 24px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              vertical-align: middle;
            }
          }
          .store {
            font-size: 12px;
            line-height: 16px;
            color: #666666;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
  }
  ::v-deep .van-loading {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    justify-content: center;
  }
}
</style>
